html,
body {
  height: 100%;
  width: 100%;
}

span {
  color: "black" !important;
}

.text {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: left;
}

.dropdowntoggle {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 1%;
  background-color: #a67eb1 !important;
}

.dropdownMenu {
  width: 100% !important;
  overflow-y: auto !important;
  max-height: 50vh !important;
}

.dpItem:focus,
.dpItem:checked,
.dpItem:active {
  background-color: #a67eb1 !important;
  outline: none !important;
}

.parallax-bot {
  /* The image used */
  background-image: url("./images/beamheroimage1.png");

  /* Full height */
  height: 55vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fade-bot {
  text-align: center;
  color: white;
  line-height: 50vh;
  height: 100%;
}

.code {
  height: 4% !important;
  line-height: 1.5 !important;
  text-align: center !important;
  background-color: #ece5ee !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.close {
  line-height: 1.4 !important;
}

.alert-dismissible .close {
  color: black !important;
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 3%;
  text-align: center;
}

.trouble-bar {
  height: 100px;
  background-color: #a67eb1;
  font-size: 250%;
  color: white;
}

.trouble-bar-text {
  margin-left: 5%;
  top: 90px;
  position: sticky;
  line-height: 250%;
}

.trouble-bar-text-tips {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.trouble-btn {
  margin-left: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  padding: 0;
}

.trouble-body {
  background-color: #ece5ee;
}

.trouble-answer {
  margin-left: 3%;
  margin-right: 3%;
  padding-top: 5px;
}

.jumbo {
  background-color: #ece5ee !important;
  text-align: center;
  height: 95vh;
}

.jumbotron {
  padding: 0 !important;
  margin: 0 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parallax {
    /* The image used */
    background-image: url("./images/beamheroimage1.png");

    /* Full height */
    height: 55vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: 42%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade {
    text-align: center;
    color: white;
    line-height: 55vh;
    height: 100%;
  }

  .caroImg {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: 25%;
  }

  .logo-white {
    width: 50%;
  }

  .privacy-card {
    margin-top: 2%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2%;
  }

  .privacy-card-body {
    margin: 1%;
  }

  .trouble-card {
    margin-left: 0;
    margin-right: 0;
  }

  .fourzerofour {
    color: #a67eb1;
    font-size: 1000%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .parallax {
    /* The image used */
    background-image: url("./images/beamheroimage1.png");

    /* Full height */
    height: 65vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade {
    text-align: center;
    color: white;
    line-height: 65vh;
    height: 100%;
  }

  .caroImg {
    width: 100%;
    height: 55vh;
    object-fit: cover;
    object-position: 30%;
  }

  .logo-white {
    width: 45%;
  }

  .privacy-card {
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 2%;
  }

  .privacy-card-body {
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
  }

  .trouble-card {
    margin-left: 1%;
    margin-right: 1%;
  }

  .fourzerofour {
    color: #a67eb1;
    font-size: 1200%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .parallax {
    /* The image used */
    background-image: url("./images/beamheroimage1.png");

    /* Full height */
    height: 75vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade {
    text-align: center;
    color: white;
    line-height: 75vh;
    height: 100%;
  }

  .caroImg {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: 30%;
  }

  .logo-white {
    width: 40%;
  }

  .privacy-card {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
  }

  .privacy-card-body {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
  }

  .trouble-card {
    margin-left: 2%;
    margin-right: 2%;
  }

  .fourzerofour {
    color: #a67eb1;
    font-size: 1500%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .parallax {
    /* The image used */
    background-image: url("./images/beamheroimage1.png");

    /* Full height */
    height: 85vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade {
    text-align: center;
    color: white;
    line-height: 85vh;
    height: 100%;
  }

  .caroImg {
    width: 100%;
    height: 65vh;
    object-fit: cover;
    object-position: 30%;
  }

  .logo-white {
    width: 35%;
  }

  .privacy-card {
    margin-top: 5%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 2%;
  }

  .privacy-card-body {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
  }

  .trouble-card {
    margin-left: 3%;
    margin-right: 3%;
  }

  .fourzerofour {
    color: #a67eb1;
    font-size: 1800%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .parallax {
    /* The image used */
    background-image: url("./images/beamheroimage1.png");

    /* Full height */
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade {
    text-align: center;
    color: white;
    line-height: 100vh;
    height: 100%;
  }

  .caroImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo-white {
    width: 30%;
  }

  .privacy-card {
    margin-top: 3%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 3%;
  }

  .privacy-card-body {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .trouble-card {
    margin-left: 4%;
    margin-right: 4%;
  }

  .fourzerofour {
    color: #a67eb1;
    font-size: 2000%;
  }
}
